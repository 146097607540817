import React, { useEffect, useState } from "react";
import AnotherBusiness from "../components/LandPlote/AnotherBusiness";
import Form from "../components/LandPlote/Form";
import HeaderBanner from "../components/LandPlote/HeaderBanner";
import LandIcons from "../components/LandPlote/Icons";
import LandDescription from "../components/LandPlote/LandDescription";
import LandGallery from "../components/LandPlote/LandGallery";
import LandMenu from "../components/LandPlote/LandMenu";
import LawText from "../components/LandPlote/LawText";
import Localization from "../components/LandPlote/Localization";
import Status from "../components/LandPlote/Status";
import TecnicalDetails from "../components/LandPlote/TecnicalDetails";
import { fetchById } from "../services/enterprises";

const LandPlote = () => {
  const enterpriseId = new URLSearchParams(window.location.search).get("id");
  const [enterprise, setEnterprise] = useState(null);
  const [showStatusDaObra, setShowStatusDaObra] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchById(enterpriseId);
        setEnterprise(response);
      } catch (error) {
        console.error("Erro ao carregar dados da API:", error);
      }
    };

    fetchData();
  }, [enterpriseId]);

  useEffect(() => {
    if (enterprise && enterprise.status_da_obra) {
      const { total } = enterprise.status_da_obra;
      setShowStatusDaObra([total].some((status) => status !== "0%"));
    }
  }, [enterprise]);

  if (!enterprise) {
    return <div>Carregando...</div>;
  }

  console.log("showStatusDaObra", showStatusDaObra);
  return (
    <div>
      <LandMenu enterprise={enterprise} />
      <HeaderBanner enterprise={enterprise} />
      <Form enterpriseId={enterpriseId} />
      <LandDescription enterprise={enterprise} />
      <LandIcons enterprise={enterprise} />
      <LandGallery enterprise={enterprise} />
      <Localization enterprise={enterprise} />
      <TecnicalDetails enterprise={enterprise} />
      {showStatusDaObra && (
        <Status status_da_obra={enterprise.status_da_obra} />
      )}
      <LawText enterprise={enterprise} />
      <AnotherBusiness />
    </div>
  );
};

export default LandPlote;
