import React from "react";
import SliderCarousel from "../components/Home/SliderCarousel";
import WhatsappButton from "../components/Home/WhatsappButton";
import WhoWeAre from "../components/Home/WhoWeAre";
import Numbers from "../components/Home/Numbers";
import Enterprises from "../components/Home/Enterprises";
import EnterpriseFilters from "../components/Home/EnterpriseFilters";
import EnterpriseCarousel from "../components/Home/EnterpriseCarousel";
import Benefits from "../components/Home/Benefits";
import BenefitsBanner from "../components/Home/BenefitsBanner";
import TestemonialCarousel from "../components/Home/TestemonialCarousel";
import Testimonials from "../components/Home/Testimonials";

const Home = () => {
  return (
    <div>
      <SliderCarousel />
      <WhatsappButton />
      <WhoWeAre />
      <Numbers />
      <Enterprises />
      <EnterpriseFilters />
      <EnterpriseCarousel />
      <Benefits />
      <BenefitsBanner />
      <Testimonials />
      <TestemonialCarousel />
    </div>
  );
};

export default Home;
