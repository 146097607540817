import React from "react";

const WhatsappButton = () => {
  return (
    <div className="nav">
      <a
        className="wts"
        href="https://api.whatsapp.com/send?phone=554137791190"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp fa-2x text-white"></i>
      </a>
    </div>
  );
};

export default WhatsappButton;
